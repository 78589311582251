.login-page{
    background-color: #d2d2da;
    height: 100vh;
    display: flex;
    align-items: center;
        .paper {
            min-width: 400px;
            padding: 20px;
            //margin-top: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .logo{
            img{
                width: 150px;
            }
            padding-bottom: 20px;
        }
        .title{
            text-align: center;
            padding: 10px;
        }
        .form {
            margin-top: 1;
        }
        .error-text {
            color: #f50057;
            margin-bottom: 5;
            text-align: center;
        }
        .submit {
            margin-top: 15px;
            width: 200px;
        }
}