.btn-remove-account{
    min-width:28px;
    height: 28px;
    border-radius: 5px;
    font-weight: 700;
    margin: 10px 20px 0px 0px;
    justify-self: right;
    Button{
        width: 100%;
    }
}