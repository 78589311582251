@import "../app-variables";

.card.sticker{
    opacity: 1;
    padding:0px;
    position: relative;
    z-index: 100;
    margin: auto;
    border-radius: 10px;
    border: black solid 1.5px;
    height: 25px;
    width: 84px;
    box-shadow: none;
    transition: box-shadow 0.1s ease-in;
    cursor: pointer;
    &.dragging{
        opacity: 1;
        position: absolute;
        z-index: 100;
    }
    &.disabled{
        border: gray solid 1.5px;
        opacity: 0.5;
        cursor: default;
    }
    &.highlight{
        box-shadow: 0px 0px 4px 3px yellow;
        transition: box-shadow 0.1s ease-in;
    }
    &.red{
        background-color: $credit;
    }
    &.blue{
        background-color: $debit;
    }
    .card-body{
        position: absolute;
        padding: 0px;
        width:90%;
        color: white;
        text-align: center;
        font-weight: 500;
        font-size: 13px;
    }
}

.popover{
    background-color: transparent;
    border: none;
    height: 180px;
    width: 220px;
    .MuiPopover-paper{
        margin: 0px;
        width: 100%;
        height: 100%;
        padding: 5px;
        text-align: center;
        display: flex;
        align-items: center;
        .popover-content{
            margin: auto;
            .popover-title{
                font-weight: 500;
                font-size: 16px;
            }
            .popover-date{
                font-weight: 450;
            }
        }
    }
}