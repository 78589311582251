.currency-modal{
    max-width: 700px;
    .currency-modal-body{
        display: flex;
        flex-direction: column;
        .currency-row{
            padding: 5px;
            justify-content: space-around;
            .btn-currency{
                width: 210px;
                height: 62px;
                border: 1px solid rgb(182, 174, 174);
                box-shadow: 1px 1px 2px grey;
                .flag-icon{
                    margin-right: 10px;
                }
            }
        }
    }
}