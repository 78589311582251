.btn-add-account{
    width:28px;
    height: 28px;
    border-radius: 5px;
    font-weight: 700;
    margin: 10px 0px 0px 20px;
    justify-self: left;
    Button{
        width: 100%;
    }
}