.btn-navigation{
    margin-top: 10px;
    position: flex;
    &.right{
        justify-self: right;
    }
    &.left{
        justify-self: left;
    }
    .btn-link{
        font-size: 20px;
        color: gray;
        background-color: white;
        border: none;
    }
}