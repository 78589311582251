@import "../app-variables";

.card{
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    .red{
        border: 2px solid $credit;
    }
    .blue{
        border: 2px solid $debit;
    }
    .board {
        display:flex;
        height:100%;
        border-radius: 10px;
        width: 100%;
        background-color: white;
        //margin: 10px;
        //justify-content: space-evenly;
        flex-direction: column;
        //padding: 1.5%;
    }
}

.board-title{
    color: white;
    padding: 1%;
    font-size: 20px;
    font-weight: 500;
    text-shadow: black 2px 2px 2px;
    height: 40px;
}

.account-board{
    margin: 0px;
    display: flex;
    flex-direction: column;
    border-radius: inherit;
    margin: 1%;
    display: flex;
    justify-content: space-around;
    width: 98%;
    height: 100%;
    //opacity: 0.99;
    &.red{
        background-color: $credit-background;
        border: 2px solid $credit;
    }
    &.blue{
        background-color: $debit-background;
        border: 2px solid $debit;
    }
    &.assets.halfView, &.liabilities.halfView{
        height: 50%;
    }
    .equity, &.liabilities{
        display: flex;
        flex-direction: column;
    }
    &.expenses, &.income{
        height: 35%;
    }
    &.hidden{
        visibility: hidden;
        max-height: 0px;
        border: none;
        margin: 0px;
    }
    .equity{
        min-height: 45%;
        max-height: 45%;
    }

    &.extend{
        height: 46%;
    }
    .row.title{
        display: flex;
        flex-direction: row;
        //justify-content: space-between;
        margin: 0px;
        flex: 0 0 15%;
    }
    .row.account-place{
        display: flex;
        flex: auto ;
        width: 100%;
        margin: inherit;
        //height: 85%;
        .account-col{
            padding: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            &.float-top{
                justify-content: start;
                margin-top: 5px;
            }
            .row{
                flex-basis: auto;
                position: relative;
                min-height: 50%;
            }
        }
    }
    
    hr.liabilities-break{
        border-top: 8px solid white;
        display: block;
        width: 100%;
        margin: 0px;
    }
    
    .total-calculation{ 
        height: 25%;
        .row {
            height: 100%;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            hr.total-break{
                border-top: 2px dashed white;
                opacity: 0.5;
                display: flex;
                width: 100%;
                margin: 0px;
                position: absolute;
                top:0px;
            }
            .totals-title{
                color: white;
                opacity: 0.5;
                font-size: 30px;
                font-weight: 500;
                position: absolute;
                margin: 0px;
            }
        }
    }
}