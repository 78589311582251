@import "../app-variables";

//Account
.tower {
    align-items: center;
    z-index: 0;
    opacity: 0.99;
    //position: static;
    &.isDragging{
        z-index: 0;
    }
    &.false{
        visibility: hidden;
    }
    &.blue{
        --color: #{$debit};
        border:none;
    }
    &.red{
        --color: #{$credit};
        border:none;
    }
    &.absolute-view{
        position: absolute;
        z-index: 50;
        right: -40px;
        top: -20px;
        opacity: 0.99;
    }
    .card.tower-body{
        border-radius: 3px;
        background-color: var(--color);
        width: 85px;
        height: auto;
        margin: 0 auto;
        border: 1px solid black;
        border-bottom: 1px dotted #BCBFCF ;
        opacity: 0.99;
        padding:0px;
        .row{
            width: 83.5px;
            min-height: 18px;
            &.tower-title{
                background-color: white;
                min-height: 18px;
                border-radius: 3px;
                .text-box{
                    border-radius: 3px;
                    width: 85px;
                    margin: 1px;
                    background-color: var(--color);
                    border: none;
                    text-align: center;
                    font-weight: 500;
                    font-size: 11px;
                    color: white;
                    line-height: 17px;
                    min-height: 17px;
                }
            }
            &.tower-slots{
                height: 100%;
                //z-index: 11;
                opacity:0.99;
                .tower-spot{
                    border-top: 1px solid #BCBFCF;
                    height: 27px;
                    opacity: inherit;
                    &.isDragging{
                        z-index: 0;
                        &:hover{
                            background-color: #fff48f;
                        }
                    }
                }
            }
        }
    }
    .tower-base-container:hover{
        cursor: pointer;
    }
    .tower-base-container{
        display: grid;
        position: relative;
        justify-items: center;
        opacity:1;
        .amountTotal{
            position: absolute;
            z-index: 5;
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            color: rgb(41, 41, 41);
            width: 100%;
        }
        .tower-base-border, .tower-base{
            grid-column: 1;
            grid-row: 1;
        }
        .tower-base-border{
            width: 105px;
            border-top: none;
            border-bottom: 24px solid black;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-radius: 4px;
        }
        .tower-base{
            top:0;
            position: absolute;
            width: 102.5px;
            border-radius: 3px;
            border-top: none;
            border-bottom: 23px solid var(--color);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }
    }
}

svg{
    cursor: pointer;
    position: absolute;
    left: 9.5px;
    top: 5.5px;
    &.false{
        visibility: hidden;
    }
}