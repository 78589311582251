@import "../app-variables";

.rct-sidebar {
    justify-content: space-between;
    height: 100%;
    background-color: $sidebar;
    padding: 10px 10px;
    align-items: center;
    .sidebar-logo {
        margin: 10px 0px;
        img {
            width: 40%;
            margin: auto;
        }
    }
    .sidebar-title{
        padding: 10px 10px 0px;
        font-size: 20px;
        color: $text-color;
        font-weight: 500;
        justify-content: center;
    }
    .sidebar-sticker{
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        justify-content: center;
        align-items: center;
    }
    .stickers-break{
        width:100%;
    }
    .transaction{
        color: $text-color;
        .date{
            padding: 10px;
            font-weight: 500;
            font-size: 18px;
            justify-content: center;
        }
        .summary{
            justify-content: center;
            padding: 0px 13px;
            font-weight: 400;
            font-size: 16px;
        }
    }
    .note{
        position: absolute;
        justify-content: center;
        display: flex;
        bottom: 115px;
        width: 100%;
        left: 0;
    }
    .transaction-break{
        position: absolute;
        bottom: 80px;
        left: 10px;
        width: 82%;
        padding: 0px 10px;
    }
    .reset-logout{
        position:absolute;
        bottom: 50px;
        justify-content: space-around;
        display: flex;
        width: 100%;
        left: 0;
    }
    .user-name{
        width: 90%;
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
    }
}