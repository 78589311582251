@import "../app-variables";

.print-button{
    margin-top: 20px;
    margin-right: 50px;
    .btn-light{
        background-color: #cac9c9;
        border: 1px solid black;
    }
}
.summary-table {
    border: 2px black;
    display: flex;
    justify-content: center;
    width: 80%;
    table.table-bordered{
        border:1px solid rgb(117, 117, 117);
        margin-top:20px;
        text-align: center;
     }
    table.table-bordered > thead > tr > th{
        border:1px solid rgb(117, 117, 117);
        padding: 7px;
        border-bottom: 2.5px solid rgb(83, 83, 83) !important;
        text-align: center;        
    }
    table.table-bordered > tbody > tr > th{
        border:1px solid rgb(117, 117, 117);
        padding: 7px;
    }
    table.table-bordered > tbody > tr > td{
        border:1px solid rgb(117, 117, 117);
        padding: 7px;
    }
    table.table-bordered > tbody > tr > td.note{
        border-bottom: 2.5px solid rgb(83, 83, 83) !important;
    }
    table.table-bordered > tbody{
        border:1px solid rgb(117, 117, 117);
        padding: 7px;
    }
    .table-title{
        font-size: 30px;
        font-weight: 500;
    }
    .blue{
        background-color: $debit-background !important;
        -webkit-print-color-adjust: exact;
    }
    .red{
        background-color: $credit-background !important;
        -webkit-print-color-adjust: exact;
    }
    .summary-row{
        background-color: rgb(223, 221, 221) !important;
    }
    .note{
        font-family: 'Architects Daughter', cursive;
    }
    .transaction-num, .date{
        width: 80px;
    }
}

.printable{
    display: flex;
    justify-content: center;
}

@media print {
    html, body {
      height: 90% !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @page {
    size: auto;
    margin: 30px;
  }